$('body').ready(function () {
  $("img[data-type='chart-state']").hide()
  let i = 1
  setInterval(() => {
    const newIndex = i + 1
    jQuery(`img[data-chart-state=${i%2}]`).fadeOut(1000)
    jQuery(`img[data-chart-state=${newIndex%2}]`).fadeIn(2500)
    i = newIndex
  }, 5000)
})
